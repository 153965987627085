import { Box, Button, Grid, Typography, createSvgIcon } from '@mui/material'
import { H1 } from 'components/Typography'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import React, { useContext } from 'react'
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { styled } from '@mui/system';
import enTranslations from 'utils/en';
import arTranslations from 'utils/ar';
import { LanguageContext } from 'contexts/LanguageContext';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate, useParams } from 'react-router-dom';

const PrinterIcon = createSvgIcon(
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path d="M1489 5105 c-145 -29 -268 -95 -380 -205 -167 -164 -228 -342 -229 -667 l0 -103 1677 0 1676 0 -6 173 c-5 123 -12 194 -27 251 -55 215 -196 388 -395 486 -161 80 -77 74 -1225 77 -822 1 -1038 -1 -1091 -12z"/>
        <path d="M609 3815 c-145 -29 -268 -95 -380 -205 -134 -132 -202 -280 -220 -485 -7 -70 -9 -350 -7 -732 4 -598 5 -621 26 -697 61 -219 199 -388 397 -486 112 -55 180 -71 333 -77 l132 -6 0 402 0 401 -62 0 c-40 0 -76 6 -98 18 -107 53 -107 211 0 264 33 17 141 18 1825 18 1684 0 1792 -1 1825 -18 107 -53 107 -211 0 -264 -22 -12 -58 -18 -97 -18 l-63 0 0 -401 0 -402 138 6 c158 6 224 21 337 77 198 97 335 265 397 486 22 78 23 85 23 784 0 699 -1 706 -23 784 -62 221 -199 389 -397 486 -165 82 32 75 -2110 77 -1555 1 -1922 -1 -1976 -12z m731 -643 c50 -25 80 -75 80 -132 0 -57 -30 -107 -80 -132 -31 -16 -68 -18 -305 -18 -237 0 -274 2 -305 18 -107 53 -107 211 0 264 31 16 68 18 305 18 237 0 274 -2 305 -18z"/>
        <path d="M1182 1083 l3 -848 24 -53 c31 -69 112 -143 181 -165 48 -16 146 -17 1165 -17 1019 0 1117 1 1165 17 69 22 150 96 181 165 l24 53 3 848 3 847 -1376 0 -1376 0 3 -847z m1838 489 c107 -53 107 -211 0 -264 -32 -16 -76 -18 -465 -18 -479 0 -476 0 -522 69 -48 71 -20 175 57 213 32 16 76 18 465 18 389 0 433 -2 465 -18z m0 -640 c107 -53 107 -211 0 -264 -32 -16 -76 -18 -465 -18 -479 0 -476 0 -522 69 -48 71 -20 175 57 213 32 16 76 18 465 18 389 0 433 -2 465 -18z"/>
    </g>
</svg>,
  'Printer',
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  borderWidth: 5,
  borderStyle: 'solid',
  borderColor: '#fff',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#fff',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#000576',
  },
}));



const PrintSuccess = () => {
  const [maxHeight, setMaxHeight] = React.useState('55vh');
  const [imgUrl, setImgUrl] = React.useState('/assets/images/Group_39.png');
  const { language } = useContext(LanguageContext);
  const translations = (language == 'en') ? enTranslations : arTranslations;

  // const isMdScreen = !useMediaQuery('(max-width:480px)');

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [disabled, setDisabled] = React.useState(true);

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          console.log('progressed');
          // setDisabled(false)
          navigate('/moi/' + printerId)
          return 0;
        }
        const diff = Math.random() * 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    setMaxHeight((isMdScreen) ? '60vh' : '78vh')
    setImgUrl((isMdScreen) ? '/assets/images/Group_39.webp' : '/assets/images/Group_39_responsive.webp')
  }, [isMdScreen]);

  const { printerId } = useParams();

  const navigate = useNavigate();
  function handleClose() {
    navigate('/moi/' + printerId)
  }

  return (
    <div>
      <img src={imgUrl} width="100%" alt="" 
        style={{height: maxHeight, width:'100%', objectFit:'cover'}} 
      />
      <div style={{backgroundColor: '#000576', paddingBottom: '40px'}}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
          <Grid
            container 
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              // margin: 5
            }}
          >
            <Typography
              variant='h6'
              sx={{
                color: 'white',
                padding: 2
              }}
              // {`${isMdScreen}`}
            >جاري عملية الطباعة</Typography>
            <Grid 
              sx={{ 
                width: '50%', 
                // padding: 2, 
                color: 'white' 
              }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={1} md={1} lg={1} rowSpacing={{ xs: 3, md: 2, lg: 2}}>
                <PrintIcon color='inherit'/>
              </Grid>
              <Grid item xs={11} md={11} lg={11}>
                <BorderLinearProgress variant="determinate" value={progress} />
              </Grid>
            </Grid>
            <Typography
              variant='h6'
              sx={{
                color: 'white',
                padding: 2
              }}
            >يرجي الانتظار. سيتم طباعة طلبك الآن</Typography>
          </Grid>
          {/* <Button disabled={disabled} size="small" onClick={handleClose} variant="contained" color='secondary'
            sx={{
              // display: display,
              backgroundColor: "yellow",
              color: "black",
              "&:hover": {
                backgroundColor: "white", // Change background color on hover
                color: "black", // Change text color on hover
              },
              "&:disabled": {
                backgroundColor: "grey",
                color: "black",
              }
            }}
          >
            {translations.ok}
          </Button> */}
      </Grid>
      </div>
    </div>
  )
}

export default PrintSuccess