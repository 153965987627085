import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import FormsCreate from './FormsCreate';
import FileFormsUpdate from './FileFormsUpdate';
import FormsFieldUpdate from './FormsFieldUpdate';
import PdfFormBuilder from './PdfFormBuilder';
import AuthGuard from 'auth/AuthGuard';
import { MatxLayout } from 'components';

const Forms = Loadable(lazy(() => import('./Forms')));
const FormRotes = {
    path: '/',
    element: (<AuthGuard><MatxLayout/> </AuthGuard>),
    children:[ 
        {
          path: 'ready-forms', 
          element: <Forms type={1} />,
        },
        {
          path: 'print-forms', 
          element: <Forms type={2} />
        },
        {
          path: '/forms/create/:type',
          element: <FormsCreate />, 
        },
        {
          path: '/forms/edit/:formId/:type', 
          element: <FileFormsUpdate />, 
        },
        {
          path: '/forms/field-edit/:formId',
          element: <FormsFieldUpdate />, 
        }

    ]
};

export default FormRotes;
