import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { createForm, createSubCategories } from "api/admin";
import { Breadcrumb, SimpleCard } from "components";
import { capitalizeFirstLetter } from "utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import FormsForm from "./FormsForm";

const FormsCreate = () => {
    const title = 'forms';

    const { formId, type } = useParams();


    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const navigate = useNavigate();
    
    const currentAdmin = { name: "", image: null, sub_category: { name: "", id: ""} }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("price_color", state.price_color)
        formData.append("price_black_and_white", state.price_black_and_white)
        formData.append("file", state.image)
        formData.append("sub_category_id", state.sub_category_id)
        
        createForm(formData).then((res) => {
            if(type == 1){
                navigate("/ready-forms");
            } else if(type == 2){
                navigate("/print-forms");
            } 
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Forms Create" }]} />
            </Box>
            <SimpleCard title="Forms Form">
                <FormsForm handleSubmit={handleSubmit} tableData={currentAdmin} type={type} />
            </SimpleCard>
        </Container>
    );
};

export default FormsCreate;
