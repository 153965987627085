/**
 * @author vijay.codeflix@gmail.com
 * admin api's
 */


import {axiosServerClientAdmin} from "./axiosServer";


/* setting api start */
/* setting api end */


/** print-price api start */
export const getSettings = () => axiosServerClientAdmin.get("print-prices");

export const updateSettings = (payload) =>
axiosServerClientAdmin.put("print-prices/1", payload, {
    headers: {
        "Content-Type": "application/json",
    },
});
/* print-price api end */

/* Category api start */

export const getCategories = (type) =>
axiosServerClientAdmin.get(`categories?status=1&filter[type]=${type}`);
export const deleteCategoriesApi = (id) =>
axiosServerClientAdmin.delete("category/" + id);

export const sort = (data) =>
axiosServerClientAdmin.post("sort", data, {
  headers: {
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
  },
});

export const categoriesUpdateStatus = (id) =>
axiosServerClientAdmin.post("categories-status/" + id + "?_method=PUT");
export const getCategoryById = (id) =>
  axiosServerClientAdmin.get("categories/" + id + "?status=1");

  export const updateCategories = (id, payload) =>
  axiosServerClientAdmin.post("categories/" + id + "?_method=PUT", payload);
  export const createCategories = (payload) =>
  axiosServerClientAdmin.post("categories", payload);
 
/* sub-category api end */


/* sub-category api start */
 
export const getSubCategories = (type) =>
  axiosServerClientAdmin.get(`sub-categories?status=1&filter[category.type]=${type}`);
export const getActiveSubCategories = () =>
  axiosServerClientAdmin.get("sub-categories");
export const getSubCategoryById = (id) =>
  axiosServerClientAdmin.get("sub-categories/" + id + "?status=1");
export const getActiveSubCategoryById = (id) =>
  axiosServerClientAdmin.get("sub-categories/" + id);
export const createSubCategories = (payload) =>
  axiosServerClientAdmin.post("sub-categories", payload);
export const updateSubCategories = (id, payload) =>
  axiosServerClientAdmin.post("sub-categories/" + id + "?_method=PUT", payload);
export const subCategoriesUpdateStatus = (id) =>
  axiosServerClientAdmin.post("sub-categories-status/" + id + "?_method=PUT");

export const deleteSubCategoriesApi = (id) =>
axiosServerClientAdmin.delete("sub-category/" + id);
/* sub-category api end */




 
/* link api start */
export const getLinks = () => axiosServerClientAdmin.get(`links?status=1`);

export const LinkUpdateStatus = (id) =>
  axiosServerClientAdmin.post("links-status/" + id + "?_method=PUT");

  export const deleteLinkApi = (id) => axiosServerClientAdmin.delete("links/" + id);

  export const updateLink = (id, payload) =>
  axiosServerClientAdmin.post("links/" + id + "?_method=PUT", payload);

  export const createLink = (payload) => axiosServerClientAdmin.post("links", payload);

  export const getLinkById = (id) =>
  axiosServerClientAdmin.get("links/" + id + "?status=1");
/* link api end */

/* form api start */

export const getForms = (type) =>
  axiosServerClientAdmin.get(`forms?status=1&filter[sub_category.category.type]=${type}`);

export const deleteFormApi = (id) => axiosServerClientAdmin.delete("forms/" + id);

export const getFormById = (id) =>
axiosServerClientAdmin.get("forms/" + id + "?status=1");

export const FormUpdateStatus = (id) =>
  axiosServerClientAdmin.post("forms-status/" + id + "?_method=PUT");

 
  export const updateForm = (id, payload) =>
  axiosServerClientAdmin.post("forms/" + id + "?_method=PUT", payload);

  export const createForm = (payload) => axiosServerClientAdmin.post("forms", payload);

  export const createFormfield = (payload) =>
  axiosServerClientAdmin.post("form-fields", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });

  export const deleteFormfield = (id) =>
  axiosServerClientAdmin.delete("form-fields/" + id);
/* form api end */


/* user form  api start*/



export const addUserValue = (payload) =>
  axiosServerClientAdmin.post("user-form", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });
  
  export const convertToPdf = (payload) =>
  axiosServerClientAdmin.post("img-to-pdf", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });

  // export const createUserForm = (payload) =>
  // axiosServerClientAdmin.post("user-form-details", payload, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     "access-control-allow-origin": "*",
  //   },
  // });


  export const printFile = (payload) =>
  axiosServerClientAdmin.post("print-file", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });
 

  export const getUserFormDetails = (id) =>
  axiosServerClientAdmin.get("user-form-details/" + id);


/* user form  api end*/


/* computer api start */
export const getComputerLabel = () => axiosServerClientAdmin.get("newcomputers");

export const getComputerById = (id) => axiosServerClientAdmin.get("newcomputers/" + id);

export const createComputerApi = (payload) =>
  axiosServerClientAdmin.post("newcomputer", payload);

export const updateComputer = (id, payload) =>
  axiosServerClientAdmin.post("newcomputers/" + id + "?_method=PUT", payload);

export const deleteComputer = (id) =>
  axiosServerClientAdmin.delete("newcomputers/" + id);
 
  
 
/* computer api end */