import { Provider } from 'react-redux';
import { BrowserRouter as Router, useRoutes,Routes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/AuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { store } from './store';
import PrivateRoute from 'navigation/PrivateRoute';
import { LanguageProvider } from './contexts/LanguageContext';
import PublicRoute from 'navigation/PublicRoute';

const App = () => { 

  return (
    <Provider store={store}>
      <SettingsProvider>
        <LanguageProvider>
        <MatxTheme>
          <AuthProvider> 
                <PrivateRoute />
                <PublicRoute />
          </AuthProvider>
        </MatxTheme>
        </LanguageProvider>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
