import { useRoutes } from "react-router-dom";
import CategoryRoutes from "views/backend/category/CategoryRoutes";
import ComputerRoutes from "views/backend/computer/ComputerRoutes";
import DashboardRoutes from "views/backend/dashboard/DashboardRoutes";
import FormRotes from "views/backend/forms/FormsRoutes";
import LinksRoutes from "views/backend/links/LinksRoutes";
import PrintJobsRoutes from "views/backend/printJobs/PrintJobsRoutes";
import PrinterRoutes from "views/backend/printer/PrinterRoutes";
import SettingRoutes from "views/backend/settings/SettingRoutes";
import SubCategoryRoutes from "views/backend/sub-categories/SubCategoryRoutes";

export default function PrivateRoute() {
    return useRoutes([
      DashboardRoutes,
      PrinterRoutes,
      ComputerRoutes,
      CategoryRoutes,
      SubCategoryRoutes,
      FormRotes,
      LinksRoutes,
      PrinterRoutes,
      PrintJobsRoutes,
      SettingRoutes,
    ]);
  }
  