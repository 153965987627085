import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import CategoriesCreate from './CategoriesCreate';
import CategoriesUpdate from './CategoriesUpdate';
import AuthGuard from 'auth/AuthGuard';
import { MatxLayout } from 'components';

const Categories = Loadable(lazy(() => import('./Categories')));

const CategoryRoutes = { 
  path: '/',
  element: (<AuthGuard><MatxLayout/> </AuthGuard>),
  children:[ 
      {
          path: 'form-categories',
          element: <Categories  type={1} />
      },
      {
          path: 'print-categories',
          element: <Categories  type={2} />
      },
      {
          path: 'link-categories',
          element: <Categories  type={3} />
      },
      {
          path: 'categories/create/:type',
          element: <CategoriesCreate />
      },
      {
        path: 'categories/edit/:categoryId/:type',
        element: <CategoriesUpdate />
    },

  ]
};

export default CategoryRoutes;
