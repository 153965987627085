import {
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { Span } from "components/Typography";
import { getImageFromUrl } from "utils/utils";
import { useState } from "react";
import FileUpload from "react-material-file-upload";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));


const CategoriesForm = ({ handleSubmit, tableData }) => {

    const [state, setState] = useState({ name: tableData.name, category_id: '', category_name: '', image: null });
    const handleChange = (event) => {
        console.log(event);
        // event.persist();
        if(event.target == undefined){

            setState({ ...state, image: event });
        }else{

        setState({ ...state, [event.target.name]: event.target.value });
        }
        // console.log(state);
    };


    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="name"
                            id="name"
                            value={state.name || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Name"
                            validators={["required"]}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <FileUpload
                            value={state.image}
                            onChange={handleChange}
                            
                        />
                    </Grid>



                </Grid>

                <Button color="primary" variant="contained" type="submit" sx={{mt:2}}>
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default CategoriesForm;
