import { CssBaseline, ThemeProvider } from '@mui/material';
import useSettings from 'hooks/useSettings';
import { useLocation } from 'react-router-dom';

const MatxTheme = ({ children }) => {
  const { settings } = useSettings();
  const { pathname } = useLocation();
  // console.log(pathname);

  const frontRoute = [
    '/moi',
    '/print-manual',
    '/form',
    '/fill-form',
    '/payment-center',
    '/link',
    '/manual-qr-code',
    '/print-static'
  ];
  

  // moi, print-manual, form, fill-form, payment-center,link, manual-qr-code, print-static
  let activeTheme = (frontRoute.includes(pathname)) ? { ...settings.themes[settings.frontTheme] } : { ...settings.themes[settings.activeTheme] };

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MatxTheme;
