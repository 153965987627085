import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import AuthGuard from 'auth/AuthGuard';
import { MatxLayout } from 'components';

const Setting = Loadable(lazy(() => import('./Setting')));

const SettingRoutes = {
  path: '/',
  element: (<AuthGuard><MatxLayout/> </AuthGuard>),
  children:[ 
      {
          path: 'settings',
          element: <Setting/>
      },

  ]
};
export default SettingRoutes;
