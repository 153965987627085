import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import LinksCreate from './LinksCreate';
import LinksUpdate from './LinksUpdate';
import AuthGuard from 'auth/AuthGuard';
import { MatxLayout } from 'components';

const Links = Loadable(lazy(() => import('./Links')));

const LinksRoutes = {

  path: '/',
    element: (<AuthGuard><MatxLayout/> </AuthGuard>),
    children:[ 
        {
          path: 'links', 
          element: <Links />,
        },
        {
          path: 'links/create', 
          element: <LinksCreate />
        },
        {
          path: '/links/edit/:linkId',
          element: <LinksUpdate />, 
        }, 
    ]
  };

export default LinksRoutes;
