import { useRoutes } from "react-router-dom";
import AuthRoutes from "views/backend/auth/AuthRoutes"; 
import HomeRoutes from "views/frontend/home/HomeRoutes";
import PrintFormRoutes from "views/frontend/print-form/PrintFormRoutes";

export default function PublicRoute() {
    return useRoutes([
      AuthRoutes,
      HomeRoutes,
      // PrintFormRoutes
    ]);
  }
  