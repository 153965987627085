import AuthGuard from "auth/AuthGuard";
import  MatxLayout  from "components/MatxLayout/MatxLayout";
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { Navigate } from "react-router-dom";

const Analytics = Loadable(lazy(() => import('./Analytics')));
const DashboardRoutes = {
    path: '/',
    element: (<AuthGuard><MatxLayout/> </AuthGuard>),
    children:[
        { path: '/', element: <Navigate to="dashboard/default" /> },
        {
            path: 'dashboard/default',
            element: <Analytics/>
        },

    ]
};

export default DashboardRoutes;
