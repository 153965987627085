import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { getLinkById, updateLink } from "api/admin";
import { Breadcrumb, SimpleCard } from "components";
import { capitalizeFirstLetter } from "utils/utils";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LinksForm from "./LinksForm";

const LinksUpdate= () => {
    const title = 'links';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    useEffect(() => {
        setLoading(true);
       
        try {
            getLinkById(linkId).then((res) => {
            setLinks({links, sub_category_id: res.data.result.sub_category.id, sub_category_name: res.data.result.sub_category.name, ...res.data.result});
            
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        // setSubCategory(error);
        }
    },[])

    const [admin, setAdmin] = useState([]);
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState({});

    // const dispatch = useDispatch();
    const navigate = useNavigate();

    const { linkId } = useParams();

    
    // const currentAdmin = { name: "", image: null, sub_category: { name: "", id: ""} }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("link", state.link)
        if(state.thumbnail !== null && state.thumbnail instanceof File){
            formData.append("thumbnail", state.thumbnail)
        }
        formData.append("sub_category_id", state.sub_category_id)
        
        updateLink(linkId, formData).then((res) => {
            navigate("/links");
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Links Create" }]} />
            </Box>
            <SimpleCard title="Links Form">
                <LinksForm handleSubmit={handleSubmit} tableData={links} />
            </SimpleCard>
            
        </Container>
    );
};

export default LinksUpdate;
