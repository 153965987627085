import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { Breadcrumb, SimpleCard } from "components";
import { capitalizeFirstLetter } from "utils/utils";
import { useNavigate } from "react-router-dom";
import LinksForm from "./LinksForm";
import { createLink } from "api/admin";

const LinksCreate = () => {
    const title = 'links';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const navigate = useNavigate();
    
    const currentAdmin = { name: "", image: null, sub_category: { name: "", id: ""} }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("link", state.link)
        formData.append("sub_category_id", state.sub_category_id)
        formData.append("thumbnail", state.thumbnail)
        
        createLink(formData).then((res) => {
            navigate("/links");
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Links Create" }]} />
            </Box>
            <SimpleCard title="Links Form">
                <LinksForm handleSubmit={handleSubmit} tableData={currentAdmin} />
            </SimpleCard>
        </Container>
    );
};

export default LinksCreate;
