import Loadable from 'components/Loadable';
import { lazy } from 'react';
import PrintOption from './PrintOption';
import Manual from './Manual';
import FormField from './FormField';
import CategoriesGrid from './CategoriesGrid';
import SubCategoriesGrid from './SubCategories';
import GenerateImage from './GenerateImage';
import PrintSuccess from './PrintSuccess';
import Link from './Link';
import PrintOptionWithTimestamp from './PrintOptionWithTimestamp';
import Hero from './Hero';

import FrontLayout from 'components/MatxLayout/FrontLayout';
const Form = Loadable(lazy(() => import('./Form')));
 
const PrintFormRoutes = {
    path: '/',
    element: <FrontLayout  />,
    children:[
        {
            path: 'print-form-new/:printerId',
            element: <Hero/>
        },
        {
            path: 'print-form-option/:printerId/:timestamp',
            element: <PrintOptionWithTimestamp/>
        },
        {
            path: 'print-manual/:printerId',
            element: <CategoriesGrid type={1}/>
        },
        {
            path: 'print-static/:printerId',
            element: <CategoriesGrid type={2}/>
        },
        {
            path: 'payment-center/:printerId',
            element: <CategoriesGrid type={3}/>
        },
        {
            path: 'print-manual/:printerId/:categoryId/:type',
            element: <SubCategoriesGrid/>
        },
        {
            path: 'form/:printerId/:subCategoryId',
            element: <Form/>
        },
        {
            path: 'print-automatic/:printerId',
            element: <Manual/>
        },
        {
            path: 'fill-form/:formId/:printerId',
            element: <FormField/>
        },
        {
            path: 'link/:subCategoryId',
            element: <Link/>
        },
        {
            path: 'generate-image/:userFormId/:printerId',
            element: <GenerateImage/>
        },
        {
            path: 'print-success/:printerId',
            element: <PrintSuccess/>
        }, 

    ]
};

export default PrintFormRoutes;
