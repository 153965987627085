import AuthGuard from "auth/AuthGuard";
import  MatxLayout  from "components/MatxLayout/MatxLayout";
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { Navigate } from "react-router-dom";
import ComputersUpdate from "./ComputersUpdate";

const Computers = Loadable(lazy(() => import('./Computers')));
const ComputerRoutes = {
    path: '/',
    element: (<AuthGuard><MatxLayout/> </AuthGuard>),
    children:[ 
        {
            path: 'computers',
            element: <Computers/>
        },
        {
            path: 'computers/edit/:computerId',
            element: <ComputersUpdate/>
        },

    ]
};

export default ComputerRoutes;
