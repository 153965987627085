import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import SubCategoriesCreate from './SubCategoriesCreate';
import SubCategoriesUpdate from './SubCategoriesUpdate';
import AuthGuard from 'auth/AuthGuard';
import { MatxLayout } from 'components';

const SubCategories = Loadable(lazy(() => import('./SubCategories')));

const SubCategoryRoutes = { 
  path: '/',
  element: (<AuthGuard><MatxLayout/> </AuthGuard>),
  children:[ 
      {
          path: 'form-sub-categories',
          element: <SubCategories  type={1} />
      },
      {
          path: 'print-sub-categories',
          element: <SubCategories  type={2} />
      },
      {
          path: 'link-sub-categories',
          element: <SubCategories  type={3} />
      },
      {
          path: 'sub-categories/create/:type',
          element: <SubCategoriesCreate />
      },
      {
        path: 'sub-categories/edit/:subCategoryId/:type',
        element: <SubCategoriesUpdate />
    },

  ]
};

export default SubCategoryRoutes;
