import {
    Autocomplete,
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { getCategories } from "api/admin";
import { Span } from "components/Typography";
import { useEffect, useState } from "react";
import FileUpload from "react-material-file-upload";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));


const SubCategoriesForm = ({ handleSubmit, tableData, type }) => {

    const [state, setState] = useState({ name: tableData.name, category_id: tableData.category_id, category: tableData.category, image: null });
    // console.log(tableData);
    const [categories, setCategories] = useState({});
    const [loading, setLoading] = useState(false);
    const handleChange = (event) => {
        if (event.target == undefined) {

            setState({ ...state, image: event });
        } else {

            setState({ ...state, [event.target.name]: event.target.value });
        }
    };

    useEffect(() => {
        setLoading(true);

        try {
            getCategories(type).then((res) => {
                setCategories(res.data.result.data);
                // console.log(res.data);
            })

        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }, [])

    const { category } = state;
    // console.log(category)

    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <TextField
                            type="text"
                            name="name"
                            id="name"
                            value={state.name || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="Name"
                            validators={["required"]}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <Autocomplete
                            options={categories}
                            disableClearable
                            defaultValue={{name: category.name, id: category.id}}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                // setFieldValue("category_id", newValue.id);
                                setState({ ...state, category_id: newValue.id });
                            }}
                            isOptionEqualToValue={(option, value) => {  return option.id == value.id }}
                            id="controllable-printer-demo"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Category Name"
                                    value={category.name || ""}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    variant="outlined"
                                    // helperText={touched.category_id && errors.category_id}
                                    // error={Boolean(touched.category_id && errors.category_id)}
                                // fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <FileUpload
                            value={state.image}
                            onChange={handleChange}

                        />
                    </Grid>



                </Grid>

                <Button color="primary" variant="contained" type="submit" sx={{ mt: 2 }}>
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default SubCategoriesForm;
