import AuthGuard from "auth/AuthGuard";
import  MatxLayout  from "components/MatxLayout/MatxLayout";
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { Navigate } from "react-router-dom";

const Printers = Loadable(lazy(() => import('./Printers')));
const PrinterRoutes = {
    path: '/',
    element: (<AuthGuard><MatxLayout/> </AuthGuard>),
    children:[ 
        {
            path: 'printers',
            element: <Printers/>
        },

    ]
};

export default PrinterRoutes;
