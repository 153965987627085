import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { createForm, createSubCategories, getFormById, updateForm } from "api/admin";
import { Breadcrumb, SimpleCard } from "components";
import { capitalizeFirstLetter } from "utils/utils";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormsForm from "./FormsForm";

const FileFormsUpdate= () => {
    const title = 'forms';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    useEffect(() => {
        setLoading(true);
       
        try {
            getFormById(formId).then((res) => {
            setForms({forms, sub_category_id: res.data.result.sub_category.id, sub_category_name: res.data.result.sub_category.name, ...res.data.result});
            
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        // setSubCategory(error);
        }
    },[])

    const [admin, setAdmin] = useState([]);
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState({});

    // const dispatch = useDispatch();
    const navigate = useNavigate();

    const { formId, type } = useParams();

    
    // const currentAdmin = { name: "", image: null, sub_category: { name: "", id: ""} }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("price_color", state.price_color)
        formData.append("price_black_and_white", state.price_black_and_white)
        if(state.image !== null){

            formData.append("file", state.image)
        }
        formData.append("sub_category_id", state.sub_category_id)
        
        updateForm(formId, formData).then((res) => {
            if(type == 1){
                navigate("/ready-forms");
            } else if(type == 2){
                navigate("/print-forms");
            } 
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };
    // console.log(forms);

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Forms Create" }]} />
            </Box>
            <SimpleCard title="Forms Form">
                <FormsForm handleSubmit={handleSubmit} tableData={forms} />
            </SimpleCard>
            
        </Container>
    );
};

export default FileFormsUpdate;
