import AuthGuard from "auth/AuthGuard";
import  MatxLayout  from "components/MatxLayout/MatxLayout"; 
import JwtLogin from "views/backend/auth/JwtLogin";
import JwtRegister from "views/backend/auth/JwtRegister"; 

const AuthRoutes = {
    path: '/',
    // element: '',
    children:[
        {
            path: 'session/signup',
            element: <JwtRegister/>
        },
        {
            path: 'session/signin',
            element: <JwtLogin/>
        },

    ]
};

export default AuthRoutes;
