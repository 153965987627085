import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'auth/authRoles';
import AuthGuard from 'auth/AuthGuard';
import { MatxLayout } from 'components';

const PrintJobs = Loadable(lazy(() => import('./PrintJobs')));

const PrintJobsRoutes =  {
  path: '/',
  element: (<AuthGuard><MatxLayout/> </AuthGuard>),
  children:[ 
      {
          path: 'reports',
          element: <PrintJobs/>
      },

  ]
};
 

export default PrintJobsRoutes;
