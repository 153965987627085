import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { createComputerApi, getComputerById, updateComputer } from "api/admin";
import { Breadcrumb, SimpleCard } from "components";
import { capitalizeFirstLetter } from "utils/utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ComputersForm from "./ComputersForm";

const ComputersUpdate = () => {
    const title = 'computers';

    const { computerId } = useParams();

    const [loading, setLoading] = useState(false);
    const [computer, setComputer] = useState({});


    useEffect(() => {
        setLoading(true);

        try {
            getComputerById(computerId).then((res) => {
                setComputer(res.data.result);

                console.log(res.data.result);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setComputer({ 'computer_id': "", 'label': '', 'id': '' });
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [])



    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const navigate = useNavigate();


    const handleSubmit = (state) => {
        if (computer.id == '' || computer.id == null) {
            const formData = new FormData();
            formData.append("computer_id", computerId)
            formData.append("label", state.label)

            createComputerApi(formData).then((res) => {
                console.log(res);
                navigate("/computers");
            }).catch(function (error) {
                if (error.response) {

                }
            });
        } else {
            const formData = new FormData();
            formData.append("label", state.label)

            updateComputer(computer.id, formData).then((res) => {
                console.log(res);
                navigate("/computers");
            }).catch(function (error) {
                if (error.response) {
                }
            });
        }
    };

    console.log(computer);

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Computer Label Update" }]} />
            </Box>
            <SimpleCard title="Computers Form">
                { <ComputersForm handleSubmit={handleSubmit} tableData={{ label: computer.label }} />}
            </SimpleCard>
        </Container>
    );
};

export default ComputersUpdate;
