import Loadable from 'components/Loadable';
import { lazy } from 'react';

const Menu = Loadable(lazy(() => import('./Home')));

const HomeRoutes = { 
    children:[
        {
            path: '/moi/:printerId',
            element: <Menu/>
        },

    ]
};
    
export default HomeRoutes;
